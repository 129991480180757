/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { memo } from 'react';
import { ReadTimeBadge } from '../ui/badge/badge';

export default memo(({ post }) => {
  const image = getImage(post.frontmatter.image);
  const imgStack = ['linear-gradient(rgba(5,15,10,.3),rgba(5,15,10,.9))', image];

  return (
    <div
      className="my-4 md:my-8 md:mx-4 rounded-lg hover:shadow-2xl"
      css={css`
        width: 325px;
        overflow: hidden;
      `}
    >
      <Link to={`${post.frontmatter.slug}`}>
        <BgImage image={imgStack}>
          <div
            className="p-4 flex-col text-white items-center text-center"
            css={css`
              min-height: 30rem;
              height: 100%;
            `}
          >
            {' '}
            <h3 className="font-bold font-display text-white h-24">{post.frontmatter.title}</h3>
            <span className="w-3/5 mt-4">
              <ReadTimeBadge
                timeToRead={post.fields.readingTime.text}
                customClass={{
                  backgroundColor: 'rgba(255,255,255,0.2)',
                  borderRadius: '.25rem',
                }}
              />
            </span>
            <p className="font-display text-sm text-white">
              {' '}
              {post.frontmatter.excerpt && post.frontmatter.excerpt.length > 360
                ? `${post.frontmatter.excerpt.substring(0, 350)}  ...`
                : post.frontmatter.excerpt}
            </p>
          </div>
        </BgImage>
      </Link>
    </div>
  );
});
