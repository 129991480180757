/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';

const Badge = ({ children, icon, iconTwo, noBackground }) => {
  const propClasses = classNames({
    '': noBackground,
    'bg-primary-100 border border-primary-200': !noBackground,
  });

  const iconPrimary = classNames({
    'fa-book-reader': icon === 'reader',
    'fa-dumbbell': icon === 'dumbbell',
    'fa-hands-helping': icon === 'hands',
    'fa-laptop': icon === 'laptop',
    'fa-square': icon === 'square',
  });

  const iconSecondary = classNames({
    'fa-paint-brush ml-1': iconTwo === 'brush',
    'fa-terminal fa-inverse': iconTwo === 'terminal',
  });

  const iconAlignment = classNames({
    '': iconTwo,
    'items-center': !iconTwo,
  });

  return (
    <span
      className={`m-1 p-1 ${propClasses} ${iconAlignment} md:inline flex justify-center items-center text-gray-700 rounded font-mono text-sm`}
      css={css`
        width: 12.5rem;
      `}
    >
      {iconTwo ? (
        <span className="fa-stack mr-1">
          <i className={`fa ${iconPrimary} fa-lg text-primary-500`} />
          <i className={`fa ${iconSecondary} fa-stack-1x fa-xs text-primary-300`} />
        </span>
      ) : (
        <i className={`fa ${iconPrimary} fa-lg text-primary-500 mr-2`} />
      )}
      {children}
    </span>
  );
};

export const ReadTimeBadge = ({ timeToRead, noBackground, noIcon, customClass }) => {
  const propClasses =
    customClass ||
    classNames({
      '': noBackground === true,
      'bg-gray-600 rounded-sm': !noBackground,
    });

  return (
    <span
      className={`font-mono text-sm ${propClasses} text-white px-2 py-1 mb-1 inline-block whitespace-no-wrap`}
      style={customClass}
    >
      {' '}
      {!noIcon && <i className="far fa-clock fa- text-white mr-2" />}
      {timeToRead}
    </span>
  );
};

export default Badge;
