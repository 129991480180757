import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import BlogPostCard from '../components/template/BlogPostCard';
import TagHeader from '../components/template/TagHeader';

export default function BlogTagTemplate({ data, pageContext }) {
  const { tag } = pageContext;
  return (
    <Layout>
      {tag && <TagHeader category={tag} />}
      <div className="container py-12">
        {' '}
        <div className="flex flex-wrap space-between row">
          {data.posts.nodes.map((post) => (
            <BlogPostCard post={post} key={post.id} />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($tag: String) {
    posts: allMdx(
      filter: {
        frontmatter: {
          tags: { in: [$tag] }
          layout: { regex: "/post/" }
          slug: { ne: null }
          draft: { ne: true }
        }
      }
    ) {
      nodes {
        id
        frontmatter {
          title
          excerpt
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
            }
          }
          slug
          date(formatString: "DD MMMM YYYY")
          author
        }
        fields {
          readingTime {
            text
          }
        }
      }
    }
  }
`;
